import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { SVG } from '@svgdotjs/svg.js'
import Tone from 'tone';
import { range } from 'ramda';
import { Button } from 'antd';
import Sadko from '../service/Sadko';
import { Chords, Harp } from '../context';
import withTune from './hocs/withTune';
import { ImageLoader } from '../service/ImageLoader';

const ChordName = styled.div`
    text-align: center;
    font-weight: bold;
    color: #aa0000;
    font-size: 20px;
    height: 32px;
`;

const ChordBlock = styled.div`
    width: 300px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    @media (max-width: 768px) {
        margin: 0 auto;
    }
`;

const HarpBlock = styled.div`
    cursor: ${({ ready }) => ready ? 'pointer' : 'default'}
`;

const ChordNameText = styled.span`
    display: inline-block;
    margin-left: 32px;
`;

const PlayButtonContainer = styled.span`
    display: inline-block;
    margin-right: -32px;
`;

const clearElement = (node) => {
  while (node && node.lastElementChild) {
    node.removeChild(node.lastElementChild);
  }
};

const selectElement = (parent, selector) => {
  const node = parent.findOne(selector);
  if (!node) {
    throw new Error(`Svg element with selector '${selector}' not found`);
  }

  return node;
};

export function Chord({ name, stringsCount, drone, tune, fireOnRender = true, instrument = {} }) {
  const { dispatch } = useContext(Chords.Context);
  const { state } = useContext(Harp.Context);
  const [renderedStringsCount, setRenderedStringsCount] = useState(null);

  stringsCount = stringsCount ? stringsCount : state.stringsCount;
  drone = drone ? drone : state.drone;

  const {
    ready = false, playNote = () => {
    }
  } = instrument;

  const harpContainerRef = useRef(null);

  /**
   *
   * @type {this}
   */
  const shape = useMemo(() => {
    const chordNameInG = Sadko.transposeChordOrFullNote(name, -Sadko.getGOffset(tune));

    return Sadko.getChordShape(chordNameInG, stringsCount, drone);
  }, [name, stringsCount, drone]);

  /**
   *
   */
  const chordName = useMemo(() => {
    return name;
  }, [name, tune]);

  /**
   *
   */
  const chordSvgPromise = useMemo(() => {
    const loadImage = async () => {
      return await ImageLoader.load(`/images/harp-chord-${stringsCount}.svg`);
    };

    return loadImage();

  }, [stringsCount]);

  /**
   *
   */
  useEffect(() => {
    if (fireOnRender && name) {
      dispatch({ type: Chords.actions.PUSH, name });
    }
  }, [fireOnRender, name, dispatch]);

  /**
   *
   */
  useEffect(() => {
    let mount = true;
    const renderChord = async () => {
      const domContainerNode = harpContainerRef.current;

      const svgElement = SVG(harpContainerRef.current);

      if (!domContainerNode || !chordSvgPromise) {
        return;
      }

      if (renderedStringsCount !== stringsCount) {
        const svg = await chordSvgPromise;
        clearElement(harpContainerRef.current);

        svgElement.svg(svg);
      }

      if (!mount) {
        return;
      }

      const noteElements = range(1, stringsCount + 1).map((stringNumber) => {
        return {
          note: selectElement(svgElement, `#note-name-${stringNumber}`),
          string: selectElement(svgElement, `#string-${stringNumber}`),
          muted: selectElement(svgElement, `#muted-${stringNumber}`),
        }
      });

      shape.forEach((muted, stringIndex) => {
        if (!noteElements[stringIndex]) {
          return;
        }
        noteElements[stringIndex].muted.attr({ visibility: muted ? 'visible' : 'hidden' });
        noteElements[stringIndex].note.tspan(
            Sadko.getStringNote(stringIndex + 1, tune, drone));
        noteElements[stringIndex].string.attr({ stroke: muted ? '#777777' : '#000000' });
      });

      setRenderedStringsCount(stringsCount);
    };
    renderChord();

    return () => (mount = false)

  }, [stringsCount, tune, drone, renderedStringsCount, shape, chordSvgPromise]);

  const onClickChord = () => {
    ready && playChord();
  };

  const playChord = () => {
    const notes = Sadko.getChordNotes(chordName, tune);

    Tone.Transport.stop();
    Tone.Transport.cancel();
    Tone.Transport.clear();

    const partNotes = notes.map((note) => ({
      name: note,
      duration: 1,
      time: 0,
      velocity: 0.7
    }));

    const chordPart = new Tone.Part(playNote, partNotes);

    chordPart.start(0);
    Tone.Transport.start();
  };

  return <ChordBlock>
    <HarpBlock ref={harpContainerRef} className='chord' ready={ready} onClick={onClickChord}/>
    {name && <ChordName>
      {ready && <PlayButtonContainer><Button type='link' shape='circle' icon='play-circle' onClick={playChord}/></PlayButtonContainer>}
      <ChordNameText>{chordName}</ChordNameText>
    </ChordName>}
  </ChordBlock>
}

export default React.memo(withTune(Chord));
