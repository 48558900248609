import React, { useContext } from 'react';
import { Harp } from '../../context';

export default function withTune(WrappedComponent) {
  return function WrapperWithTuneContext({ tune, ...props }) {
    const { state: harpState } = useContext(Harp.Context);
    props.tune = tune ? tune : harpState.tune;

    return <WrappedComponent {...props}/>;
  }
}
