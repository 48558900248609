import axios from 'axios';

const loadedImages = {};

const loadPromises = {};

const loadImage = async (path) => {
    const response = await axios(path);

    loadedImages[path] = response.data;

    return loadedImages[path];
};

export class ImageLoader {
    static async load(path) {
        if (path in loadedImages) {
            return loadedImages[path];
        }

        if (path in loadPromises) {
            return loadPromises[path];
        }

        loadPromises[path] = loadImage(path);

        return loadPromises[path];
    }
}
